import "./interesting.css";

export default function Interesting() {

    return (
        <div className="interesting">
              {/* <h2>What is the job of the alternator inside a vehicle?</h2> */}
                {/* <p>
                The alternator is responsible for charging the car battery and thus for supplying the vehicle with electricity.
                In the early days of automobile construction, electricity in the vehicle was only required for the light, which is why the name alternator came about.
                Today, apart from the headlights, it supplies all kinds of electrical devices in the vehicle with electricity. 
                These include, for example, the air conditioning, the electric windows, the speakers or the starter. 
                Other names for the alternator are generator, alternator or dynamo.
                The armature (also called rotor) of the alternator is rotated by the V-belt and motor, which creates a magnetic field in the alternator, which in turn generates electricity. 
                The regulator, which is now installed in the alternator in all standard models, then regulates this current to the correct voltage, usually 12-14 volts.
            </p> */}

                <h2 className="text-2xl font-semibold">Was ist die Aufgabe der Lichtmaschine im Fahrzeug?</h2>
                <p>
                Die Lichtmaschine ist verantwortliche für das Laden der Autobatterie, sprich sie versorgt das Fahrzeug mit Elektrizität.
                In den Anfangsjahren des Fahrzeugbaus war Elektrizität lediglich für die Scheinwerfer nötig, deshalb wird sie heute <b>Lichtmaschine</b> genannt.
                Heute versorgt sie außer den Scheinwerfern auch alle anderen Elektronikkomponenten mit Strom.
                Das beinhaltet bspw. die Klimaanlage, die elektrischen Fensterheber, die Lautsprecher oder den Starter (Anlasser). 
                Andere Name für die Lichtmaschine sind Generator, Dynamo oder auch im englischen: Alternator.
                Die Wicklung (auch Rotor genannt) der Lichtmaschine wird durch den Keilriemen und den Motor gedreht, dies erzeugt in der Lichtmaschine ein Magnetfeld, aus welchen Elektrizität erzeugt wird. 
                Der Regler der heutzutage in fast allen Lichtmaschinen verbaut ist, reguliert dann den Strom auf die korrekte Voltanzahl, übelicherweise 12-14 Volt.
                </p>

                <br/>
                <br/>
                <br/>

                {/* <h2>What is the job of the starter inside a vehicle?</h2> */}
                {/* <p>
                    The starter is a device in vehicles with internal-combustion engines which gives the initial power to start the engine so that it can run under its own power.
                    We offer only brand new starter motors in our shop. The starter is activated by the ignition lock or, in newer vehicles, by the start-stop button.
                </p> */}

                <h2 className="text-2xl font-semibold">Was ist die Aufgabe des Starters im Fahrzeug?</h2>
                <p>
                    Der Starter, auch Anlasser genannt, gibt die Initialzündung um den Motor zu starten, sodass er dann aus eigener Kraft weiterlaufen kann.
                    Der Starter wird durch drehen des Schlüssels im Zündschloss gestartet, oder wie es in neueren Fahrzeugen der Fall ist, durch den Start-Stop Knopf.
                    In unserem Shop vertreiben wir fast ausschließlich neue Starter. 
                </p>

                <img src="./starter1.jpg" alt="starter" className="starterImg"></img>
      </div>
    )
  }
  