import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import "./index.css"

// const client = new ApolloClient({
//   uri: "https://maschinenbau-dunder.myshopify.com/admin/api/2022-01/graphql.json",
//   cache: new InMemoryCache(),
//   fetchOptions: {
//     mode: 'no-cors',
//   },
// });

const client = new ApolloClient({
    // link: new HttpLink({
        uri: "https://maschinenbau-dunder.myshopify.com/admin/api/2022-01/graphql.json",
        cache: new InMemoryCache(),
        // fetchOptions: {
        //   mode: 'no-cors'
        // }
    // }),
  });

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);