import React, { useEffect, useState } from 'react'
import './topbar.css'
import { Link } from 'react-router-dom'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import logo from '../../images/logo.png'
import { databaseLocation } from '../../dummyData'
import axios from 'axios'

const Topbar = ({ loggedIn, user }) => {
	const [inCartAmount, setInCartAmount] = useState(0)

	useEffect(() => {
		let isMounted = true
		if (loggedIn) {
			axios.get(`${databaseLocation}/getInCartAmount/${user.userId}`).then((response) => {
				if (isMounted) {
					setInCartAmount(response.data[0].amount)
				}
			})
		}

		return () => {
			isMounted = false
		}
	}, [loggedIn])

	return (
		<div className='bg-white relative top-0 mt-8 z-[999]'>
			<div className='flex items-center justify-between px-5'>
				<div className='flex flex-row'>
					<Link to='/shop'>
						<img className='w-80 h-auto cursor-pointer' alt='logo' src={logo} />
					</Link>
				</div>
				<div className='flex items-center'>
					{loggedIn === true ? (
						<div className='flex gap-5'>
							<Link to='/cart' className='relative'>
								<ShoppingCartIcon
									style={{ fontSize: 40 }}
									className='border border-gray-400 rounded-full p-2 cursor-pointer text-black'
								/>
								{inCartAmount > 0 && (
									<span className='absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full size-4 flex items-center justify-center'>
										{inCartAmount}
									</span>
								)}
							</Link>
							<Link to='/profile'>
								<img
									src={user.avatar}
									alt='Avatar'
									className='size-10 rounded-full cursor-pointer object-cover'
								/>
							</Link>
						</div>
					) : (
						<div className='relative cursor-pointer text-gray-700 mr-2'>
							<Link to='/login'>
								<AdminPanelSettingsIcon className='text-black' />
							</Link>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default Topbar
