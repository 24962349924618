import React, { useState, useRef, useEffect } from 'react';

// Custom hook to detect click outside
function useClickOutside(ref, onClickOutside) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				onClickOutside();
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, onClickOutside]);
}

const Dropdown = ({ options, selectedOptions, onSelect }) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);

	useClickOutside(dropdownRef, () => setIsOpen(false)); // Close dropdown on outside click

	const handleOptionClick = (option) => {
		onSelect(option);
		setIsOpen(false);
    console.log("selectedOptions", selectedOptions)
	};

	// Display selected options or default text
	const displayText = selectedOptions.length > 0 ? selectedOptions.join(', ') : 'Filter';

	return (
		<div className="relative inline-block text-left" ref={dropdownRef}>
			<button
				onClick={() => setIsOpen(!isOpen)}
				className="inline-flex justify-between w-48 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
			>
				{displayText}
				<svg
					className="w-5 h-5 ml-2 -mr-1 text-gray-400"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 20 20"
					fill="currentColor"
					aria-hidden="true"
				>
					<path
						fillRule="evenodd"
						d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
						clipRule="evenodd"
					/>
				</svg>
			</button>

			{isOpen && (
				<div
					className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
					style={{ zIndex: 100, backgroundColor: 'white' }} // Added inline backgroundColor and zIndex
				>
					{/* Ensure background is white */}
					<div className="py-1 bg-white">
						{options.map((option) => (
							<button
								key={option}
								onClick={() => handleOptionClick(option)}
								className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
							>
								{option}
							</button>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default Dropdown;
