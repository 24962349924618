import './shop.css'
import { databaseLocation } from '../../dummyData'
import { useEffect, useState } from 'react'
import Axios from 'axios'
import ShopProductsListing from '../../components/shopProductsList/shopProductsListing'
import VehicleFilter from './components/VehicleFilter'
import TextField from '@mui/material/TextField'

export default function Shop() {
	const [vehicleList, setVehicleList] = useState([])
	const [articleList, setArticleList] = useState([])
	const [filteredList, setFilteredList] = useState([])
	const [searchText, setSearchText] = useState('')
	const [articleIds, setArticleIds] = useState([])

	const searchHandler = async (e) => {
		let allArticles = articleList
		if (articleList.length === 0) {
			await Axios.get(`${databaseLocation}/api/articles/getAllWithQuantity`).then(
				(response) => {
					// console.log("Finished fetching1")
					// console.log(response.data);
					allArticles = response.data
				}
			)
		}

		// console.log("Anzeige")
		const searchTxt = searchText.toLowerCase().replaceAll(' ', '')
		setSearchText(e.target.value)
		setArticleList(allArticles)
		if (e.target.value.length > 0) {
			setFilteredList(
				allArticles.filter(
					(article) =>
						article.articleNumber.toLowerCase().includes(searchTxt) ||
						article.oe.toLowerCase().includes(searchTxt)
				)
			)
		} else {
			setFilteredList(allArticles)
		}
	}

	return (
		<div className='shop'>
			<div className='searchBar'>
				<span className='title'>Suche nach Teilenummer</span>
				<input
					type='text'
					placeholder='Search..'
					value={searchText}
					onChange={searchHandler}
					className='border border-gray-300 rounded w-full md:w-auto'
				/>
			</div>
			{/* <VehicleFilter articleList={articleList} setArticleList={setArticleList} filteredList={filteredList} setFilteredList={setFilteredList} /> */}
			{/* <ShopProductsList key={0} article={
            {
                articleId: 0,
                brand: "Test",
                articleType: "Test",
                manufacturer: "Test",
                URL: "",
                volt: "",
                power: "",
                articleNumber: "Test",
                ebayPlus: 1
            }
        } /> */}
			{filteredList.length > 0 && (
				<div className='listings'>
					<div className='shopWidget'>
						{filteredList.slice(0, 101).map((article) => {
							return <ShopProductsListing key={article.articleId} article={article} />
						})}
					</div>
					{/* {filteredList.length <= 3 && */}

					<div className='m-6 p-12 border-solid border-2 rounded-sm border-sky-500'>
						<h3 className='text-2xl'>Nicht das richtige gefunden?</h3>
						<br />
						<span>Kontaktieren Sie uns gerne, wir finden das richtige für Sie!</span>
						{/* <span>Contact us, we will find the right part for you!</span> */}
					</div>
					{/* } */}
				</div>
			)}
		</div>
	)
}
