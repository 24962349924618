import { CalendarToday, LocationSearching, MailOutline, PermIdentity, PhoneAndroid, Publish } from '@material-ui/icons';
import './userUpdate.css';
import { Link } from "react-router-dom";
import { useState } from 'react';
import Axios from "axios";
import { databaseLocation } from '../../dummyData';

export default function UserUpdate({user, setUser}) {

    const [newUsername, setNewUsername] = useState("");
    const [newLevel, setNewLevel] = useState("");
    const [newFirstname, setNewFirstname] = useState("");
    const [newLastname, setNewLastname] = useState("");
    const [newGender, setNewGender] = useState("");
    const [newEmail, setNewEmail] = useState("");

    const handleUpdate = () => {

        if(newUsername.length > 3) { user.user = newUsername; }
        if(newLevel.length > 0) { user.level = newLevel; }
        if(newFirstname.length > 0) { user.firstname = newFirstname; }
        if(newLastname.length > 0) { user.lastname = newLastname; }
        if(newGender.length > 0) { user.gender = newGender; }

        Axios.put(`${databaseLocation}/api/user/update`, { user: user })
        .then( (response) => {
            // console.log("Successfully updated user: " + response);
        });
    }

    const handleStatusUpdate = () => {

        user.status = !user.status;

        Axios.put(`${databaseLocation}/api/user/update`, { user: user })
        .then( (response) => {
            // console.log("Successfully updated user: " + response);
        });
    }

    const avatarUploadHandler = async (event) => {
        // console.log("IMG: " + event.target.value);
        // console.log("File Name: " + event.target.files[0].name)
        // console.log("File Type: " + event.target.files[0].type)
        // console.log("File Size: " + event.target.files[0].size)

        // const response = await fetch(databaseLocation+"/cors", { mode: 'cors' });
        // console.log("Res: " + response);

        let formData = new FormData();
        formData.append("pic", event.target.files[0]);
        formData.append("userId", user.userId);
        await Axios.post(databaseLocation+"/replaceImage", formData, {
            // name: event.target.files[0].name,
            // file_type: event.target.files[0].type,
            // file_size: event.target.files[0].size,
            // userId: 47,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then( async (res) => {
            // console.log("Res2: " + await res.data);
            fetchImg();
        });
    }

    const fetchImg = () => {

        console.log("Fetching image..");

        Axios.get(databaseLocation+"/getImage/"+user.userId).then( (res) => {

            var arrayBufferView = new Uint8Array(res.data[0].image.data);
            var blob = new Blob( [ arrayBufferView ], { type: "image/jpeg" } );
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL( blob );
            user.avatar = imageUrl;
            
            var newUser = {};
            {Object.keys(user).map( (key, index) => {
                newUser[key] = user[key];
            })}

            // console.log(newUser)
            setUser(newUser);
        })
    }

  return (
    <div className="userUpdate">
        <span className="userUpdateTitle">Bearbeiten</span>
        <form className="userUpdateForm space50">
            <div className="userUpdateLeft">
                {/* <div className="userUpdateItem">
                    <label>Benutzername</label>
                    <input type="text" placeholder={user.user} className="userUpdateInput" onChange={ (e) => setNewUsername(e.target.value) } />
                </div> */}
                <div className="userUpdateItem">
                    <label>Vorname</label>
                    <input type="text" placeholder={user.firstname} className="userUpdateInput" onChange={ (e) => setNewFirstname(e.target.value) } />
                </div>
                <div className="userUpdateItem">
                    <label>Nachname</label>
                    <input type="text" placeholder={user.lastname} className="userUpdateInput" onChange={ (e) => setNewLastname(e.target.value) } />
                </div>
                <div className="userUpdateItem">
                    <label>E-Mail</label>
                    <input type="text" placeholder={user.email} className="userUpdateInput" onChange={ (e) => setNewEmail(e.target.value) } />
                </div>
                {user.level > 0 && 
                <>
                    <div className="userUpdateItem">
                        <label>Level</label>
                        <input type="text" placeholder={user.level} className="userUpdateInput" onChange={ (e) => setNewLevel(e.target.value) } />
                    </div>
                    {/* <div className="userUpdateItem">
                        <label>Geschlecht</label>
                        <input type="text" placeholder={user.gender} className="userUpdateInput" onChange={ (e) => setNewGender(e.target.value) } />
                    </div> */}
                </>}
            </div>
            <div className="userUpdateRight space50">
                <div className="userUpdateUpload">
                    <img className="userUpdateImg" src={user.avatar} alt="avatar" />
                    <label htmlFor="file">
                        <Publish className="userUpdateIcon" />
                    </label>
                    <input type="file" id="file" accept="image/png, image/jpeg" onChange={avatarUploadHandler} style={{display: "none" }}/>
                </div>
                <div className="userUpdateItem space">
                    <button className="userUpdateButton" onClick={handleStatusUpdate}>{user.status === 1 ? "Deactivate" : "Activate"}</button>
                    <button className="userUpdateButton" onClick={handleUpdate}>Bearbeiten</button>
                </div>
            </div>
        </form>
    </div>
  )
}
