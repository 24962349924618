export const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  export const productData = [
    { name: 'January', "Sales": 4000, pv: 2400, amt: 2400, },
    { name: 'February', "Sales": 3000, pv: 1398, amt: 2210, },
    { name: 'March', "Sales": 3000, pv: 1398, amt: 2210, },
  ];



  export const userRows = [
    { id: 1, username: 'Jon Snow', avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScqWFBV1tVueNmwvGdjg5LbsLEoIc6N-9GhQ&usqp=CAU", email: "jonsnow@hotmail.com", status:"active", transaction:"$120.00" },
    { id: 2, username: 'Cersei Lannister', avatar: "", email: "", status:"active", transaction:"$540.00" },
    { id: 3, username: 'Jaime Lannister', avatar: "", email: "", status:"active", transaction:"$420.00" },
  ];

export const databaseLocation = process.env.REACT_APP_DATABASE_LOCATION || "https://lagerwelt3000.herokuapp.com";
//   export const databaseLocation = process.env.REACT_APP_DATABASE_LOCATION || "https://lagerwelt.herokuapp.com";