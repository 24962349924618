import { useState } from 'react';
import "./sidebar.css";
import SidebarMenu from "./SidebarMenu";
import MenuIcon from '@mui/icons-material/Menu';

export default function Sidebar({loggedIn}) {

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => {
        setSidebar(!sidebar);
    }

  return (
    <>
        <MenuIcon fontSize="large" sx={{ fontSize: 40 }} className="text-2xl cursor-pointer fixed border rounded-full p-2 bg-sky-200 -ml-2" onClick={showSidebar} />
        <div className={sidebar ? "sidebarWrapper active" : "sidebarWrapper"}>
            <SidebarMenu loggedIn={loggedIn} setSidebar={setSidebar}/>
        </div>
    </>
  )
}
